<template>
  <div>
    <HomePageTop/>
    <div class="page">
      <header :style="goodsListIsNull||list.length==0?'margin-bottom:30px;':''">
        <div class="headerTitle">{{ $fanyi('待确认商品') }}</div>
        <div class="operationContainer">
          <div class="flexAndCenter">
            <input v-model="checked" style="cursor: pointer" type="checkbox" @change="allChoice"/>
            <div class="quanXuan">{{ $fanyi("全选") }}</div>
          </div>
          <div class="btn" style="margin: 0 12px 0 22px;" @click="delGoods('')">{{ $fanyi('移除搜索结果') }}</div>
          <div class="btn" @click="goodsJoinPallet('')">{{ $fanyi('商品放入货盘') }}</div>
        </div>
      </header>
      <div v-show="!goodsListIsNull||list.length>0" v-loading="loading" style="margin: 24px 0 30px">
        <div class="SearchConterCrosswise">
          <div v-for="(item, index) in list" :key="index"
               :data-aplus-report="item.traceInfo"
               :style="item.hoverStatus === true ? 'border: 1px solid #B4272B;' : ''"
               class="SearchConterCrosswiseCon" data-tracker="offer" @mouseleave="goodsMouseleave(index)">
            <!-- 放图片的div -->
            <div class="SearchConterCrosswiseImg" @mouseenter="goodsMouseenter(index)"
                 @click.stop="toCommodityDetails(item)">
              <div :style="item.hoverStatus === true?'':'justify-content: flex-end;'" class="goodsOperationContainer">
                <div v-show="item.hoverStatus === true" @click.stop="delGoods(item)">{{ $fanyi("移除") }}</div>
                <input v-model="item.checked" type="checkbox" @click.stop="$fun.removeEventBubbling()"/>
              </div>
              <el-image :src="item.pic" class="biggoodsImg"
                        style="width: 261px; height: 261px;max-width: 261px;max-height: 261px">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
            </div>
            <!-- 显示其他信息的div -->
            <div v-show="item.hoverStatus === false"
                 class="rests">
              <p :title="item.goods_detail.titleT" class="goodsTitle">
                <span v-if="item.goods_detail.fromPlatform==1688" class="type">1688</span>
                {{ item.goods_detail.titleT }}
              </p>
              <p>
              <span>
                {{ Number(item.price).toFixed(2) }}{{
                  $fanyi("元")
                }}（{{ (Number(item.price) * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
              </span>
                <span v-if="item.goods_detail.fromPlatform==1688" style="color: #999999">販売数<span
                    style="color: #B4272D!important;">{{
                    item.month_sold
                  }}</span>件</span>
              </p>
              <div style="display: flex;flex-wrap: wrap">
                <div v-if="item.goods_detail.fromPlatform==1688"
                     style="display: flex;flex-wrap: wrap;margin-bottom: 6px">
                  <div v-for="(newItem,newIndex) in item.goods_detail.offerIdentities" :key="newIndex">
                    <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                         class="powerfulMerchantsContainer">
                      <img alt="" src="../../assets/1688/powerfulMerchantsIcon.png">
                      <span>スーパーメーカー</span>
                    </div>
                    <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                         class="superFactoryContainer">
                      <img alt="" src="../../assets/1688/superFactoryIcon.png">
                      <span>実力商家</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.goods_detail.fromPlatform==1688" class="repurchaseRate">リピート率
                  <span>{{ Number(item.repurchase_rate) }}</span>％
                </div>
              </div>
            </div>
            <div v-show="item.hoverStatus === true"
                 class="shopInfo">
              <div v-show="item.hoverStatus === true" class="findSimilarityContainer"
                   @click.stop="goodsJoinPallet(item)">{{ $fanyi("放入货盘") }}
              </div>
              <div class="shopInfoHeader" style="justify-content: space-between">
                <div class="goodCompare" @click.stop="addCommercialMatch(item)">
                  <img alt="" src="../../assets/1688/goodCompare.png">
                  比較
                </div>
                <div class="goodsFavorite" @click.stop="favoriteGoodsAdd(item)">
                  <img v-if="item.goodsFavoriteStatus" alt="" src="../../assets/SearchResultsPage/Collected.png">
                  <img v-else alt="" src="../../assets/SearchResultsPage/collection.png">
                  {{ item.goodsFavoriteStatus ? $fanyi("取消收藏") : $fanyi("收藏") }}
                </div>
              </div>
              <div
                  v-if="item.goods_detail.fromPlatform==1688"
                  :style="item.shopInfo==undefined||$fun.isArray(item.shopInfo)||(!$fun.isArray(item.shopInfo)&&item.shopInfo.shopName==undefined&&item.shopInfo.year==undefined)?'border-bottom:none':''"
                  class="shopDataContainer">
                <div class="newRateContainer">
                  <div style="margin-right: 7px">総合評点</div>
                  <el-rate
                      v-model="item.goods_detail.tradeScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="shopDataBottom">
                  <div class="repurchaseRate" style="margin-right:6px">リピート率
                    <span>{{ Number(item.repurchase_rate) }}</span>％
                  </div>
                  <div class="repurchaseRate" style="width: 128px;">販売数<span>{{
                      item.month_sold
                    }}</span>件
                  </div>
                </div>
              </div>
              <div
                  v-if="item.shopInfo!=undefined&&!$fun.isArray(item.shopInfo)&&item.goods_detail.fromPlatform==1688&&item.shopInfo.shopName!=undefined&&item.shopInfo.year!=undefined"
                  class="shopNameContainer">
                <div
                    v-if="item.shopInfo.sellerIdentities!=undefined&&item.shopInfo.sellerIdentities.indexOf('tp_member')==-1"
                    class="yearContainer">
                  <div><img alt="" src="../../assets/1688/shopIcon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div
                    v-if="item.shopInfo.sellerIdentities!=undefined&&item.shopInfo.sellerIdentities.indexOf('tp_member')!=-1"
                    class="tpMemberContainer">
                  <div><img alt="" src="../../assets/1688/tp_member_icon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div :title="item.shopInfo.shopName" class="shopName cursorPointer"
                     @click="shopPageJump(item)">
                  {{ item.shopInfo.shopName }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="keywordLoading" class="keywordLoadingContainer">
            <div class="flexAndCenter">
              <img alt="" src="@/assets/loading.gif">
              <div>読み込み中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "@/components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";

export default {
  components: {Foot, HomePageTop},
  data() {
    return {
      checked: false,
      from: {
        user_pallet_id: this.$route.query.user_pallet_id,
        page: 1,
        pageSize: 50,
      },
      list: [],
      loading: false,
      keywordLoading: false,
      goodsListIsNull: false,
      palletProductWaitListInfo: {}
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  mounted() {
    window.addEventListener("scroll", this.getbottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getbottom);
  },
  created() {
    this.getPalletProductWaitList();
  },
  methods: {
    getPalletProductWaitList() {
      this.loading = true;
      this.$api.palletProductWaitList(this.from).then((res) => {
        this.loading = false;
        this.keywordLoading = false;
        this.palletProductWaitListInfo = res.data;
        if ((Number(res.data.total) < 50)) {
          this.keywordLoading = undefined;
        }
        if (res.data.data.length == 0) {
          this.goodsListIsNull = true;
        } else {
          res.data.data.forEach((goodsItem) => {
            goodsItem.hoverStatus = false;
            goodsItem.checked = false;
            if (goodsItem.whiteImage != undefined && goodsItem.whiteImage != '') {
              goodsItem.imgUrl = goodsItem.whiteImage
            }
            if (goodsItem.goods_detail.tradeScore != undefined) {
              goodsItem.goods_detail.tradeScore = Number(goodsItem.goods_detail.tradeScore)
            }
            if (goodsItem.shopInfo != undefined && !this.$fun.isArray(goodsItem.shopInfo) && goodsItem.shopInfo.shopTime != undefined) {
              if (goodsItem.shopInfo.shopTime.length != 19) {
                goodsItem.shopInfo.year = 0
              } else {
                let year = goodsItem.shopInfo.shopTime.substr(0, 4);
                var date = new Date();
                goodsItem.shopInfo.year = Number(date.getFullYear()) - Number(year);
              }
            }
            this.list.push(goodsItem);
            this.goodsListIsNull = false;
            this.favoriteGoodsCheckIn();
          })
        }
      });
    },
    // 查看商品是否已收藏
    favoriteGoodsCheckIn() {
      let ids = [];
      this.list.forEach((goodsItem) => {
        goodsItem.goodsFavoriteStatus = false;
        ids.push(goodsItem.goods_id);
      });
      this.$api.favoriteGoodsCheckIn({
        goods_id: ids,
        shop_type: 1688
      })
      .then((res) => {
        if (res.code != 0) return false;
        res.data.forEach((resItem, resIndex) => {
          this.list[resIndex].goodsFavoriteStatus = resItem.inFavorite;
        });
      });
    },
    //是否全选
    allChoice() {
      this.list.forEach((goodsItem) => {
        goodsItem.checked = this.checked;
      })
    },
    //商品移除待确认列表
    delGoods(val) {
      let goods_id = [];
      if (val == '') {
        this.list.forEach((goodsItem) => {
          if (goodsItem.checked) {
            goods_id.push(goodsItem.id);
          }
        })
      } else {
        goods_id.push(val.id);
      }
      if (goods_id.length == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.$confirm(
          this.$fanyi('是否把选中商品移除？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.storeBlack({
          ids: goods_id
        })
        .then((res) => {
          if (res.code != 0) return this.$message.success(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.getPalletProductWaitList();
        });
      }).catch(() => {

      });
    },
    //商品放入货盘
    goodsJoinPallet(val) {
      let goods_id = [];
      if (val == '') {
        this.list.forEach((goodsItem) => {
          if (goodsItem.checked) {
            goods_id.push(goodsItem.id);
          }
        })
      } else {
        goods_id.push(val.id);
      }
      if (goods_id.length == 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.$api.productConfirm({
        ids: goods_id
      })
      .then((res) => {
        if (res.code != 0) return this.$message.success(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg));
      });
    },
    //页面触底事件
    getbottom() {

    },
    //跳转到商品详情页
    toCommodityDetails(val) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过货盘待确认商品的商品展示进入商品详情页统计');
      }
      this.$fun.toCommodityDetails(val.goods_id, val.from_platform)
    },
    //鼠标移出事件
    goodsMouseleave(index) {
      this.list[index].hoverStatus = false;
      this.$forceUpdate();
    },
    //鼠标移入事件
    goodsMouseenter(index) {
      this.list[index].hoverStatus = true;
      this.$forceUpdate();
    },
    //跳转到店铺详情页
    shopPageJump(item) {
      let super_factory_status = undefined;
      let powerful_merchants_status = undefined;
      if (item.sellerIdentities != undefined) {
        item.sellerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
        item.sellerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
      }
      if ((item.shopInfo.shopName != '' && item.shopInfo.shopName != undefined) && ((item.shopInfo.shop_id != '' && item.shopInfo.shop_id != undefined) || (item.shopInfo.shopId != '' && item.shopInfo.shopId != undefined)) && (item.shopInfo.wangwang != '' && item.shopInfo.wangwang != undefined)) {
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shopInfo.shop_id == undefined ? item.shopInfo.shopId : item.shopInfo.shop_id}&wangName=${item.shopInfo.wangwang}&shopName=${item.shopInfo.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
      }
    },
    //加入对比
    addCommercialMatch(val) {
      let commercialMatchList = []
      if (localStorage.getItem("commercialMatchList") != null) {
        commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
      }
      if (commercialMatchList.length < 30) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('货盘待确认商品增加比较商品数量统计');
        }
        let flag = commercialMatchList.findIndex(item => item.title == val.goods_detail.titleT)
        if (flag != -1) {
          this.$message.error('該当商品は追加済みです、他の商品をお選び下さい。')
        } else {
          commercialMatchList.push({
            imgUrl: val.pic,
            title: val.goods_detail.titleT,
            titleC: val.goods_detail.titleC,
            price: val.price,
            shopType: val.shopType,
            goodsId: val.goodsId,
            monthSold: val.month_sold == undefined ? '' : Number(val.month_sold),
            repurchaseRate: val.repurchase_rate == undefined ? '' : Number(val.repurchase_rate),
            shopName: val.goods_detail.shopName,
            link: `https://www.rakumart.com/ProductDetails?goods_id=${val.goods_id}&fromPlatform=${val.from_platform}`
          })
          localStorage.setItem("commercialMatchList", JSON.stringify(commercialMatchList));
          this.$store.commit('setCommercialMatchGoodsCount', commercialMatchList.length)
          this.$message.success('操作が成功しました')
        }
      } else {
        this.$message.error('最大30個の商品を追加できます')
      }
    },
    //添加收藏商品
    favoriteGoodsAdd(item) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: item.from_platform,
        goods_id: item.goods_id,
        title: item.goods_detail.titleC,
        image_url: item.pic,
        price: item.price,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$message.success(this.$fanyi(res.msg))
          this.$forceUpdate();
        });
      } else {
        let arr = [];
        arr.push({
          shop_type: item.from_platform,
          goods_id: item.goods_id
        })
        this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 1400px;
  margin: 24px auto 0;
}

header {
  background: #fff;
  border-radius: 6px;

  .headerTitle {
    height: 70px;
    padding-left: 30px;
    line-height: 70px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #DFDFDF;
  }

  .operationContainer {
    height: 84px;
    padding-left: 30px;
    display: flex;
    align-items: center;

    .btn {
      width: 96px;
      height: 36px;
      background: #B4272B;
      border-radius: 4px;
      cursor: pointer;
      line-height: 36px;
      text-align: center;
      font-size: 12px;
      color: #FEFEFE;
    }

    input {
      margin-right: 6px;
    }
  }
}

.SearchConterCrosswise {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  min-height: 500px;
  position: relative;

  .type {
    width: 46px;
    height: 18px;
    display: inline-block;
    background: #FF4000;
    border-radius: 2px;
    margin-right: 7px;
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
  }

  > .SearchConterCrosswiseCon:nth-child(5n) {
    margin-right: 0 !important;
  }

  > .SearchConterCrosswiseCon {
    margin-right: 18.5px;
    margin-bottom: 15px;
    position: relative;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 6px;
    height: 430px;

    &:last-child {
      margin-right: 0;
    }

    background-color: white;
    width: 265px;

    > div {
      cursor: pointer;

      &.SearchConterCrosswiseImg {
        width: 261px;
        overflow: hidden;
        height: 261px;
        position: relative;
        transition: 0.3s;

        .goodsOperationContainer {
          position: absolute;
          width: 100%;
          display: flex;
          justify-content: space-between;
          top: 0;
          left: 0;
          z-index: 1;

          div {
            width: 60px;
            height: 32px;
            background: #B4272B;
            border-radius: 4px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
          }
        }

        > .goodsImg {
          background-color: transparent;
        }

        > .el-image {
          width: 100%;
          min-height: 100%;
        }
      }

      &.SearchConterCrosswiseImg:hover {
        > .el-image {
          transform: scale(1.2, 1.2);
        }
      }

      &.rests,
      &.newRests {
        box-sizing: border-box;
        padding: 13px 20px 12px 17px;

        p {
          font-size: 12px;

          font-weight: 400;
          color: #000000;

          &.goodsTitle {
            height: 40px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;

            //p {
            //  width: 46px;
            //  height: 18px;
            //  border-radius: 2px;
            //  background: url("../../assets/1688/goodTag.png") no-repeat;
            //  background-size: 100% 100%;
            //}
          }

          &:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            > span {
              line-height: 30px;
              font-size: 12px;
              color: #B4272B;
            }
          }
        }
      }

      &.newRests {
        background: #B4272B;

        p {
          color: #fff;

          &:nth-child(2) {
            > span {
              color: #fff !important;
            }
          }
        }
      }

      .realignmentContainer {
        display: flex;
        flex-wrap: wrap;
        margin: 6px 0;

        div {
          height: 24px;
          background: #FFEFEF;
          border-radius: 4px;
          line-height: 12px;
          text-align: center;
          color: #FF4000;
          font-size: 12px;
          padding: 6px;
          box-sizing: border-box;
        }

        div:first-child {
          margin-right: 6px;
        }
      }

      .repurchaseRate {
        width: 100px;
        height: 24px;
        background: #F6F6F6;
        border-radius: 4px;
        line-height: 24px;
        text-align: center;
        color: #333333;
        font-size: 12px;

        span {
          color: #B4272D;
        }
      }
    }
  }
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 84px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
  }
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 131px !important;

  span {
    color: #3700E1 !important;
  }
}

.shopInfo {
  padding: 0 15px;
  position: absolute;
  left: 0;
  top: 52%;
  background: #fff;
  box-sizing: border-box;
  width: 261px;
  animation: slideUp 1s ease forwards;

  .findSimilarityContainer {
    position: absolute;
    left: 0;
    top: -31px;
    height: 32px;
    width: 261px;
    background: #E0787E;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
  }

  .findSimilarityContainer:hover {
    background: #C40622;
  }

  .shopInfoHeader {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;

    .goodsFavorite, .goodCompare {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      cursor: pointer;
      font-size: 12px;
    }

    .goodCompare {
      border-right: 1px solid #DFDFDF;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  .shopDataContainer {
    padding: 20px 0 15px;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    cursor: default;

    .newRateContainer {
      display: flex;
      font-size: 16px;
      color: #999999;
      margin-bottom: 14px;
    }

    .shopDataBottom {
      display: flex;
      align-items: center;
    }
  }

  .shopNameContainer {
    padding: 15px 0;
    display: flex;
    align-items: center;

    .yearContainer, .tpMemberContainer {
      width: 62px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F72A2B;
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        width: 26px;
        height: 24px;
        background: #F72A2B;
        border-radius: 4px 0 0 4px;

        img {
          width: 18px;
          height: 17px;
        }
      }

      div:last-child {
        flex: 1;
        font-size: 12px;
        color: #F72A2B;
      }
    }

    .tpMemberContainer {
      border: 1px solid #FF6000 !important;

      div:first-child {
        background: #FF6000 !important;
      }

      div:last-child {
        color: #FF6000 !important;
      }
    }

    .shopName {
      width: 178px;
      font-size: 14px;
      color: #000000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/* 定义动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.keywordLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;

  img {
    width: 40px;
    margin-right: 20px;
  }

  div {
    font-size: 14px;
  }
}

input[type="checkbox"]:checked {
  background: #4A91E9;
  border: none;
}
</style>